:root {
  /*Basic Pallete*/
  --prim-texto: #252733;
  --prim-vermelho: #991919;
  --secun-branco: #ffffff;

  --secun-cinza: #4b4b4d;
  --texto-branco: #e8e8e8;

  --neutro-claro: #d7d7d7;
  --neutro-medio: #878787;
  --neutro-escuro: #0b0b0b;

  --erro-vermelho: #bf0000;
  --sucesso-verde: #4aa000;
  --alerta-amarelo: #e2d723; /*Yellow Peril*/
  --funcao-azul: #42BECF;

  /*Text Size from Kenzo's RGBWallet*/
  --text-smallest: 13px;
  --text-small: 16px;
  --text-medium: 18px;
  --text-big: 24px;
  --text-bigger: 36px;
  --text-thicc: 48px;
  --text-extra-thicc: 56px;
  --text-titan: 144px;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  /* font-size: 100%;
  font: inherit;
  vertical-align: baseline; */
}
