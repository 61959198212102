.switch {
  position: relative !important;
  display: inline-block !important;
}
.switch-input {
  display: none !important;
}
.switch-label {
  display: block;
  width: 52px !important;
  height: 3rem !important;
  text-indent: -150%;
  clip: rect(0 0 0 0);
  color: transparent;
  user-select: none;
}

.switch-label::before,
.switch-label::after {
  content: "";
  display: block !important;
  position: absolute !important;
  cursor: pointer;
}
.switch-label::before {
  width: 55px !important;
  height: 1.5rem !important;
  background-color: var(--erro-vermelho);
  border-radius: 9999em;
  -webkit-transition: background-color 0.25s ease;
  transition: background-color 0.25s ease;
}
.switch-label::after {
  top: 0.1rem;
  left: 0.15rem;
  right: 0rem;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--neutro-claro);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.45);
  -webkit-transition: left 0.25s ease;
  transition: left 0.25s ease;
}
.switch-input:checked + .switch-label::before {
  background-color: var(--sucesso-verde);
}
.switch-input:checked + .switch-label::after {
  left: 33px;
}
